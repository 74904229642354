import GraphQLRequest from 'Utilities/GraphQLClient/GraphQLClient'
import { urlOnlyQuery } from '/graphql/productResultPage.fragment'

const GetPRP = async (url, fetchPrecedingPages = false) => {
    try {

        let variables = {
            url,
            fetchPrecedingPages
        }

        let response = await GraphQLRequest('/graphql/discovery', urlOnlyQuery, variables)

        if (!response?.productResultPageFromUrlOnly) {
            throw `CSR page no response! ${url}`
        }

        if (response?.productResultPageFromUrlOnly?.redirectUrl) {
            console.error(`CSR page redirected from ${url} to ${response.productResultPageFromUrlOnly.redirectUrl}`,)
            window.location.href = response.productResultPageFromUrlOnly.redirectUrl
            return;
        }

        if (response?.productResultPageFromUrlOnly?.should404) {
            const err = new Error(`CSR page got a 404! ${url}`)
            err.statusCode = 404
            throw err
        }
        
        return response.productResultPageFromUrlOnly;

    } catch (err) {
        console.error('CSR page error', err)
    }

}


export default GetPRP